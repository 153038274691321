<script setup lang="ts">
import { type SelectorObject } from '@/types/components/SelectorObject'
import { EColors, type Colors } from '@/types/constants/ColorValues'
import { computed, ref, watch } from 'vue'
import CustomField from '../Fields/CustomField.vue'
import { TypographyItem } from '../Typography'

type Props = {
    options?: SelectorObject[]
    label?: string
    placeholder?: string
    modelValue?: string | number
    input?: string
    hasError?: boolean
    errorText?: string
    disabled?: boolean
    loading?: boolean
    type?: Colors
    field?: string
    clearOnSelect?: boolean
    hideFooter?: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'typing', value: string): void
    (e: 'select', value: SelectorObject, event: PointerEvent): void
    (e: 'blur', value?: string | number): void
}
const emit = defineEmits<Emits>()

const inputField = ref(props.modelValue)

const handleTyping = (value: string) => {
    inputField.value = value
    emit('typing', value)
}

const handleBlur = () => {
    inputField.value = props.input ? props.input : props.modelValue
    emit('blur', inputField.value)
}

const handleSelect = (value: SelectorObject, event: PointerEvent) => emit('select', value, event)

const computedType = computed(() =>
    props.hasError ? EColors.DANGER : props.type ?? EColors.PRIMARY
)

const inputRef = ref()
defineExpose({ inputRef })

watch(
    () => props.modelValue,
    newValue => (newValue ? (inputField.value = newValue) : undefined)
)
</script>

<template>
    <CustomField
        :label="label ?? ''"
        :placeholder="$t('common.typeYourSearch')"
        :type="computedType"
        :disabled="disabled"
        :message="errorText">
        <b-autocomplete
            ref="inputRef"
            openOnFocus
            :placeholder="placeholder ?? $t('common.typeYourSearch') ?? label"
            :field="field ?? 'name'"
            v-model="inputField"
            :data="options"
            :class="`is-${type}`"
            :loading="loading"
            :clear-on-select="clearOnSelect"
            icon-right="chevron-down"
            @select="handleSelect"
            @typing="handleTyping"
            @blur="handleBlur"
            clearable
            :disabled="disabled"
            v-bind="$attrs">
            <template #default="props">
                <slot name="default" v-bind="props">
                    <TypographyItem
                        :label="props.option[field ?? 'name']"
                        :class="props.option.name === modelValue ? 'selected-option' : undefined" />
                </slot>
            </template>
            <template #footer>
                <slot name="footer" />
            </template>
            <template #empty>
                {{ $t('common.noOption') }}
            </template>
        </b-autocomplete>
    </CustomField>
</template>

<style scoped lang="scss">
.autocomplete {
    & :deep(::placeholder) {
        color: var(--color-placeholder);
    }
    & :deep(.input) {
        :placeholder {
            color: var(--color-placeholder);
        }
    }
    & :deep(.dropdown-menu) {
        padding-top: unset;
        & .dropdown-header {
            background-color: rgba($primary, 0.4);
        }
        & .dropdown-content {
            padding: unset;
            background-color: var(--color-background-secondary);
            border: 1px solid $primary;
            color: var(--color-text);
            & .dropdown-item {
                color: var(--color-text);
                white-space: normal;
                width: 100%;
                &:has(.selected-option) {
                    background-color: rgba($primary, 0.7);
                }
                &:hover {
                    background-color: $primary;
                    color: var(--color-text);
                }
            }

            & a:not(:first-child) {
                border-top: 1px solid $primary;
            }
        }
    }
    & :deep(.dropdown-footer) {
        cursor: default;
        background-color: rgba($primary, 0.4);
        padding-top: unset;
        padding-bottom: unset;
    }
}
</style>
