<script setup lang="ts">
import DeviceCard from '@/components/DeviceComponents/Card/DeviceCard.vue'
import { GridLayout, StackLayout } from '@/components/Layouts'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import { useGridSize } from '@/hooks/useGridSize'
import { EGridSize } from '@/types/enum/ViewsUtilsEnum'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import type { SocketDeviceDetectionModel } from '@/types/socket/SocketDetectionModel'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { ref } from 'vue'
import { EColors } from '../../../types/constants/ColorValues'
import IconButton from '../../Buttons/IconButton.vue'
import DividerItem from '../../Divider/DividerItem.vue'
import BaseDialog from '../../Modals/Dialog/BaseDialog.vue'

type Props = {
    data: DeviceModel[]
    statuses: AlertSocketMessageModel[]
    detections?: SocketDeviceDetectionModel[]
    loading?: boolean
    showNextEvent?: boolean
    showNextCheckpoint?: boolean
    editPermission?: PermissionsEnums
    editButtonHref?: string
}
defineProps<Props>()
type Emits = {
    (e: 'delete', device: DeviceModel): void
}
defineEmits<Emits>()

const gridSize = useGridSize({
    smallscreen: EGridSize.IS_MOBILE,
    mobile: EGridSize.IS_MOBILE,
    tablet: EGridSize.IS_TABLET - 1,
    desktop: EGridSize.IS_DESKTOP - 1,
    widescreen: EGridSize.IS_WIDESCREEN - 1
})

const getDeviceStatus = (device: DeviceModel) => ({
    battery: device.battery,
    plugged: device.plugged,
    signal: device.signal,
    network_type: device.network_type
})

const selectedDevice = ref<DeviceModel>()

const isMobileLayout = ref(isMobile())
isMobileEvent(e => (isMobileLayout.value = e.matches))
</script>

<template>
    <StackLayout :gap="8" class="table-side full-width">
        <StackLayout
            direction="column"
            :gap="8"
            :class="`left-side outlook-mode ${selectedDevice ? 'outlook-mode' : 'full-width'}`">
            <div class="outlook-layout full-width">
                <GridLayout
                    :maxItemPerRow="isMobileLayout || !selectedDevice ? gridSize : undefined"
                    :gap="8"
                    class="relative full-width grid-outlook">
                    <LoadingSpinner :isLoading="!!loading" />
                    <DeviceCard
                        v-for="(device, key) in data"
                        :key="key"
                        :data="device"
                        :status="{
                            status: parseInt(device.status as string),
                            ...getDeviceStatus(device),
                            ...(statuses?.find(
                                item => item.device_id === (device.device_id ?? device.id)
                            ) ?? {})
                        }"
                        :detections="
                            detections?.find(
                                item => item.device_id === (device.device_id ?? device.id)
                            )?.detections ?? []
                        "
                        :showNextEvent="showNextEvent"
                        :showNextCheckpoint="showNextCheckpoint"
                        @click="selectedDevice = device"
                        :class="`full-width ${
                            (selectedDevice?.device_id ?? selectedDevice?.id) ===
                            (device.device_id ?? device.id)
                                ? 'selected-device'
                                : undefined
                        }`">
                        <template #content>
                            <slot name="cardContent" v-bind="device" />
                        </template>
                    </DeviceCard>
                </GridLayout>
            </div>
            <slot />
        </StackLayout>
        <DividerItem v-if="selectedDevice" orientation="vertical" variant="middle" isResponsive />
        <div v-if="selectedDevice && !isMobileLayout" class="right-side full-width">
            <StackLayout direction="column" :gap="8">
                <IconButton
                    icon="arrow-right"
                    :type="EColors.PRIMARY"
                    @click="selectedDevice = undefined"
                    class="fit-content" />
                <slot
                    name="outlookMode"
                    v-bind="{
                        device: {
                            ...selectedDevice,
                            ...getDeviceStatus(selectedDevice),
                            ...(statuses?.find(
                                item =>
                                    item.device_id ===
                                    (selectedDevice?.device_id ?? selectedDevice?.id)
                            ) ?? {})
                        }
                    }" />
            </StackLayout>
        </div>
        <BaseDialog
            :open="!!selectedDevice && isMobileLayout"
            @close="selectedDevice = undefined"
            full-screen
            class="outlook-mode-modal"
            noFooter>
            <template #content>
                <StackLayout direction="column" :gap="8" class="full-height">
                    <IconButton
                        icon="arrow-right"
                        :type="EColors.PRIMARY"
                        @click="selectedDevice = undefined"
                        class="fit-content" />
                    <slot name="outlookMode" v-bind="{ device: selectedDevice }" />
                </StackLayout>
            </template>
        </BaseDialog>
    </StackLayout>
    <StackLayout v-if="!data?.length" class="align-self-center no-record">
        <LoadingSpinner :isLoading="!!loading" />
        {{ $t('common.noRecord') }}
    </StackLayout>
</template>

<style scoped lang="scss">
.device-table {
    overflow-y: auto;
}
.left-side {
    height: 100%;
    overflow-y: auto;
    padding: 0 8px 8px 0;
}
.right-side {
    height: 100%;
    overflow-y: auto;
    padding: 0 8px 8px 0;
}
.table-side {
    overflow-y: auto;
    container: outlook-container / inline-size;
}

.selected-device {
    border-width: 4px;
    background-color: var(--color-background-primary);
}

.outlook-mode {
    min-width: 33%;
}

@container outlook-container (width < 1000px) {
    .grid-class {
        grid-template-columns: repeat(1, 1fr);
    }
}
@container outlook-container (width > 1000px) {
    .grid-class {
        grid-template-columns: repeat(2, 1fr);
    }
}

.outlook-mode-modal {
    & :deep(.device-detail) {
        height: 100%;
        overflow-y: auto;
    }
    & :deep(.b-tabs) {
        height: 100%;
    }
    & :deep(.tab-content) {
        height: 100%;
        overflow-y: auto;
        padding-right: 8px;
    }

    & :deep(.full-table-layout) {
        padding: unset;
    }
}
</style>
