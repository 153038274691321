import { API_PREFIX } from '@/config/api'
import api from '@/helpers/api'
import type { ESortBy } from '@/types/enum/SortByEnum'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { LatestDetectionsParams } from '@/types/models/LatestDetectionsParams'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import { buildQueryUrl } from '@/utils/queryUtils'
import type { AxiosResponse } from 'axios'

const API_DETECTION_PREFIX = `${API_PREFIX}/detections`

const detectionApi = {
    getDetections: (
        params?: Partial<PaginationType> & {
            filters?: Partial<DetectionModel>
            sort?: Record<string, ESortBy | number>
        },
        noQueryParam?: boolean
    ): Promise<PaginationDataType<DetectionModel[]>> =>
        api
            .get(`${API_DETECTION_PREFIX}${buildQueryUrl(params, noQueryParam)}`)
            .then(response => response.data),

    getLatestDetections: (
        params?: LatestDetectionsParams
    ): Promise<AxiosResponse<DetectionModel[]>> =>
        api
            .get(`${API_DETECTION_PREFIX}/latest${buildQueryUrl(params)}`)
            .then(response => response.data),

    /* getSearchDetections: (params?: Partial<PaginationType>, field: any, value: any) =>
        api
            .get(`${API_PREFIX}/detections/${field}/${value}`, { params: params })
            .then(response => Promise.resolve(response.data)), */

    getDetectionsCsv: (params?: Partial<PaginationType>) =>
        api
            .get(`${API_DETECTION_PREFIX}/export${buildQueryUrl(params)}`, {
                headers: { Accept: 'text/csv' }
            })
            .then(response => {
                const universalBOM = '\uFEFF'
                const link = document.createElement('a')
                link.target = '_blank'
                link.href =
                    'data:text/csv;charset=utf-8,' +
                    encodeURIComponent(universalBOM + response.data)
                link.download = 'detections.csv'
                link.click()

                return Promise.resolve(response.data)
            })
}
export default detectionApi
