<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { StackLayout } from '@/components/Layouts'
import { EKeyCategory, EKeyCategoryValues } from '@/types/enum/KeyCategoryEnum'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { computed, ref } from 'vue'
import DeviceOnlineStatus from '../../Typography/DeviceOnlineStatus.vue'
import DeviceAssignCheckpointAction from '../../components/DeviceAssignCheckpointAction.vue'
import DeviceBaseActions from '../../components/DeviceBaseActions.vue'
import DeviceGlobalInformations from '../InformationsTab/components/DeviceGlobalInformations.vue'
import DeviceBottomSideConfiguration from './ConfigurationComponents/DeviceBottomSideConfiguration.vue'
import DeviceFiltersConfiguration from './ConfigurationComponents/DeviceFiltersConfiguration.vue'
import DeviceLeftSideConfiguration from './ConfigurationComponents/DeviceLeftSideConfiguration.vue'
import DeviceRightSideConfiguration from './ConfigurationComponents/DeviceRightSideConfiguration.vue'

type Props = {
    storedData?: Partial<DeviceModel>
    data: Partial<DeviceModel>
    status?: AlertSocketMessageModel
    templateKeys: DeviceTemplateKeysModel[]
    isInEditMode: boolean
    showAdvanced: boolean
}
const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'update', data: Partial<DeviceModel>): void
    (e: 'refresh'): void
    (e: 'hasAdvancedConfig', value: boolean): void
}>()

const updateDeviceValue = (value: Partial<DeviceModel>) => emit('update', value)

const parsedConfig = computed<Record<string, DeviceTemplateKeysModel[]>>(() =>
    EKeyCategoryValues.reduce(
        (prev: Record<string, DeviceTemplateKeysModel[]>, current: EKeyCategory) => {
            const filteredConfig = props.templateKeys?.filter(
                configItem => configItem.category === current
            )
            /* if (filteredConfig?.find(item => item.is_advanced)) emit('hasAdvancedConfig', true)
            else emit('hasAdvancedConfig', false) */
            return filteredConfig?.length
                ? {
                      ...prev,
                      [current]: filteredConfig
                  }
                : prev
        },
        {}
    )
)

const isMobileLayout = ref(isMobile())
isMobileEvent(e => (isMobileLayout.value = e.matches))

const refs = ref<any>({
    filtersRef: undefined,
    leftSideRef: undefined,
    rightSideRef: undefined,
    bottomSideRef: undefined
})

const hasError = computed(
    () =>
        refs.value?.filtersRef?.hasError ||
        refs.value?.leftSideRef?.hasError ||
        refs.value?.rightSideRef?.hasError ||
        refs.value?.bottomSideRef?.hasError
)
const hasAdvancedConfig = computed(
    () =>
        refs.value?.filtersRef?.hasAdvancedConfig ||
        refs.value?.leftSideRef?.hasAdvancedConfig ||
        refs.value?.rightSideRef?.hasAdvancedConfig ||
        refs.value?.bottomSideRef?.hasAdvancedConfig
)
defineExpose({ hasError, hasAdvancedConfig })

const dataToShow = computed(() =>
    props.isInEditMode ? props.data : props.storedData ?? props.data
)
</script>

<template>
    <StackLayout direction="column" :gap="16" isResponsive>
        <StackLayout direction="row" :gap="16" isResponsive>
            <InformationBaseCard
                :label="$t('device.detail.informations.informationsTitle')"
                :class="`full-width fit-height ${isMobileLayout ? 'order-2' : 'order-1'}`">
                <DeviceGlobalInformations
                    :data="dataToShow"
                    :isInEditMode="isInEditMode"
                    @update="updateDeviceValue">
                    <DeviceOnlineStatus
                        :status="status?.status ?? parseInt(data.status as string)" />
                </DeviceGlobalInformations>
            </InformationBaseCard>
            <InformationBaseCard
                :class="`full-width fit-height ${isMobileLayout ? 'order-1' : 'order-2'}`"
                :label="$t('common.actions')">
                <DeviceBaseActions>
                    <StackLayout direction="column" :gap="8">
                        <slot name="actions" />
                        <DeviceAssignCheckpointAction
                            :device="storedData"
                            @refresh="$emit('refresh')" />
                    </StackLayout>
                </DeviceBaseActions>
            </InformationBaseCard>
        </StackLayout>
        <DeviceFiltersConfiguration
            :ref="el => (refs.filtersRef = el)"
            :data="dataToShow"
            :isInEditMode="isInEditMode"
            :showAdvanced="showAdvanced"
            :config="parsedConfig"
            @update="$emit('update', $event)"
            @refresh="$emit('refresh')" />
        <StackLayout direction="row" :gap="16" isResponsive class="full-width">
            <DeviceLeftSideConfiguration
                :ref="el => (refs.leftSideRef = el)"
                :data="dataToShow"
                :isInEditMode="isInEditMode"
                :showAdvanced="showAdvanced"
                :config="parsedConfig"
                @update="$emit('update', $event)"
                @refresh="$emit('refresh')" />
            <DeviceRightSideConfiguration
                :ref="el => (refs.rightSideRef = el)"
                :data="dataToShow"
                :isInEditMode="isInEditMode"
                :showAdvanced="showAdvanced"
                :config="parsedConfig"
                @update="$emit('update', $event)"
                @refresh="$emit('refresh')" />
        </StackLayout>
        <DeviceBottomSideConfiguration
            :ref="el => (refs.bottomSideRef = el)"
            :data="dataToShow"
            :isInEditMode="isInEditMode"
            :showAdvanced="showAdvanced"
            :config="parsedConfig"
            @update="$emit('update', $event)"
            @refresh="$emit('refresh')" />
    </StackLayout>
</template>

<style scoped lang="scss">
.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}
</style>
