<script setup lang="ts">
import { DeviceDetailPage } from '@/components/DeviceComponents'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import deviceApi from '@/services/device.service'
import deviceConfig from '@/services/deviceConfig.service'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { defaultCreateDevice } from '@/types/models/DeviceCreateModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import { EOrganizationsDevicesPerm } from '@/types/permissions/OrganizationsPermissionsEnum'
import { ERentalsPerm } from '@/types/permissions/RentalsPermissionsEnum'
import { isEqual } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { EAlertActions } from '../../store/alertStore/AlertStoreTypes'
import type { EDeviceStatus } from '../../types/enum/DeviceStatusEnum'
import DeviceRentalActions from './components/DeviceRentalActions.vue'

type Props = {
    device?: DeviceModel
    tabsMultilined?: boolean
}
const props = defineProps<Props>()

const { t } = useI18n()

const route = useRoute()
const deviceStore = ref({ ...defaultCreateDevice })

const localDeviceId = computed(() =>
    props.device
        ? props.device.device_id ?? props.device.id
        : route.params.device_id
        ? parseInt(route.params.device_id.toString())
        : parseInt(route.params.id?.toString())
)

const loading = ref(false)

const data = ref<Partial<DeviceModel>>({ ...defaultCreateDevice })
const templateKeys = ref<DeviceTemplateKeysModel[]>([])
const getDeviceConfig = (deviceId: number) =>
    deviceConfig
        .getDeviceTypeConfigByVersion(deviceId)
        .then(res => (templateKeys.value = res.data?.config_keys ?? []))

const getDevice = () => {
    loading.value = true
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.FETCH_API_DEVICE}`, localDeviceId.value)
        .then((res: DeviceModel) => {
            data.value = { ...res }
            deviceStore.value = { ...res }
            if (res.device_type_id) return getDeviceConfig(localDeviceId.value)
            templateKeys.value = []
        })
        .finally(() => (loading.value = false))
}

watch(
    () => localDeviceId.value,
    (newDeviceId, oldDeviceId) => {
        if (newDeviceId && newDeviceId !== oldDeviceId) getDevice()
    },
    { immediate: true }
)

const save = () => {
    if (!localDeviceId.value) return
    if (!isEqual(data.value, deviceStore.value)) {
        loading.value = true
        deviceApi
            .updateDeviceDevice(localDeviceId.value, {
                ...data.value,
                config: data.value.config
            })
            .then(res => {
                data.value = { ...data.value, ...res.data }
                deviceStore.value = { ...deviceStore.value, ...res.data }
                store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
                    action: EAlertActions.SUCCESS,
                    message: t(`device.api.success.${EDeviceActions.UPDATE_DEVICE}`, {
                        name: data.value.name
                    })
                })
            })
            .catch(() =>
                store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
                    action: EAlertActions.ERROR,
                    message: t(`device.api.error.${EDeviceActions.UPDATE_DEVICE}`, {
                        name: data.value.name
                    })
                })
            )
            .finally(() => {
                loading.value = false
            })
    }
    // TODO : dialog success
}

const updateDeviceValue = (value: Partial<DeviceModel>) =>
    (data.value = { ...data.value, ...value })

const deviceStatus = computed(() => ({
    battery: props.device?.battery ?? deviceStore.value?.battery,
    plugged: props.device?.plugged ?? deviceStore.value?.plugged,
    signal: props.device?.signal ?? deviceStore.value?.signal,
    status: (props.device?.status as EDeviceStatus) ?? (deviceStore.value?.status as EDeviceStatus),
    network_type: props.device?.network_type ?? deviceStore.value?.network_type
}))
</script>

<template>
    <DeviceDetailPage
        :storedData="deviceStore"
        :data="data"
        :status="deviceStatus"
        :templateKeys="templateKeys"
        :editPermission="EOrganizationsDevicesPerm.ORGANIZATIONS_DEVICES_EDIT"
        :tabsMultilined="tabsMultilined"
        @save="save"
        @update="updateDeviceValue"
        @refresh="getDevice"
        :loading="loading">
        <template #actions>
            <PermissionWrapper :permission="ERentalsPerm.RENTALS_EDIT">
                <DeviceRentalActions v-if="!!localDeviceId" :deviceId="localDeviceId" />
            </PermissionWrapper>
        </template>
    </DeviceDetailPage>
</template>
