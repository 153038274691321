<script setup lang="ts">
import { DeviceDetailPage } from '@/components/DeviceComponents'
import DeviceBaseActions from '@/components/DeviceComponents/components/DeviceBaseActions.vue'
import { ClickableTypography, TypographyItem, TypographyWithPrefix } from '@/components/Typography'
import { ADMIN_ORGANIZATIONS_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import deviceConfig from '@/services/deviceConfig.service'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { defaultCreateDevice } from '@/types/models/DeviceCreateModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { isEqual } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import type { EDeviceStatus } from '../../../types/enum/DeviceStatusEnum'
import DeviceTypeActionComponent from './components/DeviceTypeActionComponent.vue'

type Props = {
    device?: DeviceModel
    tabsMultilined?: boolean
}
const props = defineProps<Props>()

const route = useRoute()
const deviceStore = ref(store.state.device.device)

const localDeviceId = computed(() =>
    props.device
        ? props.device.device_id ?? props.device.id
        : route.params.device_id
        ? parseInt(route.params.device_id.toString())
        : parseInt(route.params.id?.toString())
)

const data = ref<Partial<DeviceModel>>({ ...defaultCreateDevice })
const templateKeys = ref<DeviceTemplateKeysModel[]>([])
const getDeviceConfig = (deviceId: number) =>
    deviceConfig
        .getDeviceTypeConfigByVersion(deviceId)
        .then(res => (templateKeys.value = res.data?.config_keys ?? []))

const getDevice = () =>
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.FETCH_DEVICE}`, localDeviceId.value)
        .then(res => {
            data.value = { ...res }
            deviceStore.value = { ...res }
            if (res.device_type_id) return getDeviceConfig(localDeviceId.value)
            templateKeys.value = []
        })

watch(
    () => localDeviceId.value,
    (newDeviceId, oldDeviceId) => {
        if (newDeviceId && newDeviceId !== oldDeviceId) getDevice()
    },
    { immediate: true }
)

const save = () => {
    if (!isEqual(data.value, deviceStore.value))
        store
            .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.UPDATE_DEVICE}`, {
                deviceId: localDeviceId.value,
                deviceData: { ...data.value, roles: ['device'] }
            })
            .then(res => {
                data.value = { ...data.value, ...res }
                deviceStore.value = { ...data.value, ...res }
            })
}
const updateDeviceValue = (value: Partial<DeviceModel>) => (data.value = value)

const deviceStatus = computed(() => ({
    battery: props.device?.battery ?? deviceStore.value?.battery,
    plugged: props.device?.plugged ?? deviceStore.value?.plugged,
    signal: props.device?.signal ?? deviceStore.value?.signal,
    status: (props.device?.status as EDeviceStatus) ?? (deviceStore.value?.status as EDeviceStatus),
    network_type: props.device?.network_type ?? deviceStore.value?.network_type
}))
</script>

<template>
    <DeviceDetailPage
        :storedData="deviceStore"
        :data="data"
        :status="deviceStatus"
        :templateKeys="templateKeys"
        :editPermission="EAdminPerm.ADMIN_DEVICES"
        noDetectionLink
        @save="save"
        @update="updateDeviceValue"
        :loading="store.state.device.isLoading">
        <template #informationsContent>
            <TypographyWithPrefix
                v-if="data.organization_name && data.organization_id"
                :prefix="$t('device.model.organizationName')">
                <template #edit>
                    <ClickableTypography
                        v-if="data.organization_name"
                        :label="data.organization_name"
                        :href="ADMIN_ORGANIZATIONS_ROUTES.buildUrl(data.organization_id)" />
                    <TypographyItem v-else :label="$t('common.undefined')" />
                </template>
            </TypographyWithPrefix>
        </template>
        <template #informationsActions></template>
        <template #actions>
            <DeviceBaseActions
                :deviceId="localDeviceId"
                :organizationId="data.organization_id"
                :organizationName="data.organization_name"
                @refresh="getDevice()" />
            <DeviceTypeActionComponent :data="data" @refresh="getDevice()" />
        </template>
    </DeviceDetailPage>
</template>
