<script setup lang="ts">
import IconItem from '@/components/Icon/IconItem.vue'
import { StackLayout } from '@/components/Layouts'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import TypographyWithPrefix from '@/components/Typography/TypographyWithPrefix.vue'
import { EColors } from '@/types/constants/ColorValues'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import moment from 'moment'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DeviceOnlineStatus from '../Typography/DeviceOnlineStatus.vue'

type Props = {
    data: Partial<DeviceModel>
    showNextEvent?: boolean
    showNextCheckpoint?: boolean
    status?: AlertSocketMessageModel
}
const props = defineProps<Props>()

const { t } = useI18n()

const getMinutesFromlastHello = (value: Date | null) => {
    const minutes = moment().diff(moment(value), 'minutes')
    return minutes > 0
        ? t('device.status.lastHelloBefore15Minutes', {
              minutes
          })
        : t('device.status.lastHelloNow', {
              minutes
          })
}
const lastHelloDateParsing = (value?: Date | null) =>
    value
        ? moment(value).isAfter(moment().subtract(15, 'minutes'))
            ? getMinutesFromlastHello(value)
            : t('device.status.lastHelloAfter15Minutes')
        : undefined

const pingtime = ref(lastHelloDateParsing(props.status?.pingtime ?? props.data?.pingtime))
const interval = ref()
onMounted(
    () =>
        (interval.value = setInterval(
            () =>
                (pingtime.value = lastHelloDateParsing(
                    props.status?.pingtime ?? props.data?.pingtime
                )),
            600
        ))
)
onBeforeUnmount(() => {
    clearInterval(interval.value)
})
</script>

<template>
    <StackLayout
        direction="column"
        class="device-card-content full-width full-height space-between">
        <StackLayout direction="column">
            <TypographyWithPrefix
                v-if="showNextEvent && data.event"
                :prefix="$t('device.title.currentEventTitle')"
                :label="data.event?.name ?? ''"
                boldLabel />
            <TypographyItem
                v-else-if="data.event === null"
                :label="$t('device.title.noEventAssociated')" />
            <TypographyWithPrefix
                v-if="showNextCheckpoint && data.checkpoint"
                :prefix="$t('device.title.checkpointAssociatedTitle')"
                :label="data.checkpoint?.name ?? ''"
                boldLabel />

            <slot />
        </StackLayout>
        <StackLayout direction="column">
            <StackLayout direction="row" :gap="8">
                <DeviceOnlineStatus :status="status?.status ?? parseInt(data.status as string)" />
                <TypographyWithPrefix
                    v-if="!!status?.status && status?.signal && status.signal <= 2"
                    :label="$t('device.status.lowSignal')">
                    <IconItem icon="triangle-exclamation" :type="EColors.WARNING" />
                </TypographyWithPrefix>
            </StackLayout>
            <TypographyItem v-if="data.pingtime" :label="pingtime" />
            <TypographyWithPrefix
                :prefix="$t('device.model.serialNumber')"
                :label="data.serial_number ?? ''"
                boldLabel />
        </StackLayout>
    </StackLayout>
</template>

<style scoped lang="scss">
.device-card-content {
    & :deep(span) {
        font-size: small;
    }
    & :deep(svg) {
        width: 15px;
    }
}
</style>
