<script setup lang="ts">
import { InputField } from '@/components/Fields'
import EventCheckpointAutocomplete from '@/components/Filters/components/EventCheckpointAutocomplete.vue'
import { GridLayout } from '@/components/Layouts'
import DeviceFilterAutocomplete from '@/components/Selector/components/DeviceFilterAutocomplete.vue'
import { useGridSize } from '@/hooks/useGridSize'
import { ELocalStorage } from '@/types/enum/LocalStorageEnum'
import type { DetectionFilters } from '@/types/filters/DetectionFilters'
import type { LocalStorageFilter } from '@/types/filters/DeviceFilters'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import type { EventModel } from '@/types/models/EventModel'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import { ref } from 'vue'
type Props = {
    filters: DetectionFilters
    deviceOptions?: AssociatedDeviceModel[]
}

defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<DetectionModel>): void
    (e: 'associates', value?: AssociatedDeviceModel[]): void
}
const emit = defineEmits<Emits>()

const gridSize = useGridSize()
const storedEvent = ref(getItemFromStorage<LocalStorageFilter>(ELocalStorage.FILTERS)?.event)

const handleChangeEvent = (value: Partial<DetectionModel>) => {
    storedEvent.value = undefined
    handleChange(value)
}

const handleChange = (value: Partial<DetectionModel>) => {
    if (!value) return
    storedEvent.value = undefined
    emit('change', value)
}

const selectedEvent = ref<EventModel>()
const handleSelectEvent = (event?: EventModel) => (selectedEvent.value = event)
</script>

<template>
    <GridLayout :maxItemPerRow="gridSize" :gap="8" class="full-width flex-wrap" isResponsive>
        <EventCheckpointAutocomplete
            :event="storedEvent"
            :event_id="filters.event_id"
            :checkpoint_id="filters.checkpoint_id"
            keepEventInStorage
            filterAllEvents
            @change="handleChangeEvent($event)"
            @selectEvent="handleSelectEvent" />
        <DeviceFilterAutocomplete
            :filters="filters"
            :deviceOptions="selectedEvent?.detection_devices"
            @change="handleChangeEvent($event)" />
        <InputField
            :label="$t('detection.filters.bib')"
            :modelValue="filters.bib"
            :prefix="$t('unit.number')"
            @change="handleChange({ bib: $event })" />
    </GridLayout>
</template>
