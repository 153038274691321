<script setup lang="ts">
import TokenService from '@/services/token.service'
import type { DetectionModel } from '@/types/models/DetectionModel'
import {
    defaultSocketDetectionModel,
    type SocketCheckpointDetectionModel,
    type SocketDetectionModel,
    type SocketDeviceDetectionModel,
    type SocketEventDetectionModel
} from '@/types/socket/SocketDetectionModel'
import { io } from 'socket.io-client'
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { SOCKET_RETRY_DELAY, buildDetectionArray, buildSocketStateArray } from './socketUtils'

type Props = {
    device_ids?: number[]
    event_ids?: number[]
    checkpoint_ids?: number[]
}
const props = defineProps<Props>()
type Emits = {
    (e: 'update', value: SocketDetectionModel, latestDetections: DetectionModel[]): void
}
const emit = defineEmits<Emits>()

const URTIME_DETECTIONS_URL = `${
    import.meta.env.VITE_SOCKET_URL ?? window.location.origin
}/urtime-detections`

const socketState = ref<Props>()

const socketDetections = ref<SocketDetectionModel>({
    ...defaultSocketDetectionModel
})
const socket = io(URTIME_DETECTIONS_URL, {
    autoConnect: false,
    retries: 5,
    path: '/ws/urtime-detections',
    transports: ['websocket'],
    query: {
        ...socketState.value,
        token: TokenService.getLocalAccessToken()
    },
    auth: { token: TokenService.getLocalAccessToken() }
}).on('message', (res: DetectionModel[] | string) => {
    if (typeof res === 'string' && res === 'Authentication error')
        setTimeout(() => socket.disconnect().connect(), SOCKET_RETRY_DELAY)
    if (res.length && socketState.value?.event_ids)
        socketDetections.value.event_detections = buildDetectionArray<SocketEventDetectionModel>(
            res as DetectionModel[],
            socketDetections.value.event_detections,
            'event_id'
        )
    if (res.length && socketState.value?.device_ids?.length)
        socketDetections.value.device_detections = buildDetectionArray<SocketDeviceDetectionModel>(
            res as DetectionModel[],
            socketDetections.value.device_detections,
            'device_id'
        )
    if (res.length && socketState.value?.checkpoint_ids?.length)
        socketDetections.value.checkpoint_detections =
            buildDetectionArray<SocketCheckpointDetectionModel>(
                res as DetectionModel[],
                socketDetections.value.checkpoint_detections,
                'checkpoint_id'
            )
    emit('update', socketDetections.value, res as DetectionModel[])
})

const setupDetectionsArrays = (
    event_ids?: number[],
    checkpoint_ids?: number[],
    device_ids?: number[]
) => {
    if (device_ids?.length)
        //@ts-ignore
        socketDetections.value.device_detections =
            buildSocketStateArray<SocketDeviceDetectionModel>(
                device_ids,
                socketDetections.value.device_detections,
                'device_id'
            )
    if (checkpoint_ids?.length)
        //@ts-ignore
        socketDetections.value.checkpoint_detections =
            buildSocketStateArray<SocketCheckpointDetectionModel>(
                checkpoint_ids,
                socketDetections.value.checkpoint_detections,
                'checkpoint_id'
            )
    if (event_ids?.length)
        //@ts-ignore
        socketDetections.value.event_detections = buildSocketStateArray<SocketEventDetectionModel>(
            event_ids,
            socketDetections.value.event_detections,
            'event_id'
        )
}

const setSocket = (device_ids?: number[], event_ids?: number[], checkpoint_ids?: number[]) => {
    if (device_ids?.length)
        !device_ids?.every(
            item => socketState.value?.device_ids?.find(deviceId => deviceId === item)
        )
            ? updateSocketState('device_ids', device_ids)
            : null

    if (event_ids?.length)
        !event_ids.every(item => socketState.value?.event_ids?.find(eventId => eventId === item))
            ? updateSocketState('event_ids', event_ids)
            : null
    if (checkpoint_ids?.length)
        !checkpoint_ids.every(
            item => socketState.value?.checkpoint_ids?.find(checkpointId => checkpointId === item)
        )
            ? updateSocketState('checkpoint_ids', checkpoint_ids)
            : null
}

const reloadSocket = () => {
    if (socket.connected) socket.disconnect().connect()
    else socket.connect()
}

const updateSocketState = (key: string, value: unknown) => {
    setupDetectionsArrays(props.event_ids, props.checkpoint_ids, props.device_ids)
    socketState.value = { ...socketState.value, [key]: value }
    socket.io.opts.query = { ...socket.io.opts.query, [key]: value }
    reloadSocket()
}

onMounted(() => setSocket(props.device_ids, props.event_ids, props.checkpoint_ids))
watch(
    () => props.device_ids,
    newValue => setSocket(newValue)
)
watch(
    () => props.event_ids,
    newValue => setSocket(undefined, newValue)
)
watch(
    () => props.checkpoint_ids,
    newValue => setSocket(undefined, undefined, newValue)
)

onBeforeUnmount(() => {
    socket.disconnect()
})
</script>
<template><slot /></template>
