<script setup lang="ts">
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { BaseTable } from '@/components/Table'
import TitleTypography from '@/components/Typography/TitleTypography.vue'
import type { DetectionModel } from '@/types/models/DetectionModel'
import { ref } from 'vue'
import EventDetectionsColumns from './EventDetectionsColumns.vue'

type Props = {
    detections: DetectionModel[]
}
defineProps<Props>()

const table = ref()
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <TitleTypography :title="$t('event.detection.liveDetections')" class="title-x-large bold" />
        <BaseTable v-bind:ref="table" :data="detections">
            <template #columns>
                <EventDetectionsColumns />
            </template>
        </BaseTable>
    </StackLayout>
</template>
