export const devicesFr = {
    actions: {
        reassignCheckpoint: 'Réassigner un checkpoint',
        attachDeviceToOrganization: 'Attacher à un client',
        detachDeviceToOrganization: 'Détacher du client',
        changeDeviceType: "Change de type d'appareil"
    },
    api: {
        error: {
            createDevice: "Erreur lors de la création de l'appareil {name} ",
            deleteDevice: "Erreur lors de la suppression de l'appareil {name} ",
            fetchDevice: "Erreur lors de la récupération de l'appareil",
            fetchDevices: 'Erreur lors de la récupération des appareils',
            updateDevice: "Erreur lors de la modification de l'appareil {name}"
        },
        success: {
            createDevice: "Création de l'appareil {name} effectuée avec succès !",
            deleteDevice: "Suppression de l'appareil {name} effectuée avec succès !",
            fetchDevice: "Récupération de l'appareil effectuée avec succès !",
            fetchDevices: 'Récupération des appareils effectuée avec succès !',
            updateDevice: "Modification(s) de l'appareil {name} effectuée(s) avec succès !"
        }
    },
    buttons: {
        hideAdvanced: 'Cacher la configuration avancée',
        showAdvanced: 'Montrer la configuration avancée'
    },
    configuration: {
        errors: {
            rangeError: 'La valeur doit être comprise entre {min} et {max}',
            excludedError: 'La valeur ne peut pas être {excluded}'
        },
        apiKey: "Clé d'API uRTime",
        apnModem: 'Modem APN',
        apnProtocol: 'Protocol APN',
        apnType: "Type d'APN",
        bluetooth: 'Bluetooth',
        buzzer: 'Buzzer',
        connectionMethod: 'Méthode de connexion',
        gps: 'GPS',
        gpsPingInterval: 'Intervalle entre fix GPS',
        pingInterval: "Intervalle d'envoi detect/hello",
        pirSensor: 'Capteur PIR',
        rfidChannel: 'Canal RFID',
        wifiPassword: 'WIFI Mot de passe',
        wifiSSID: 'WIFI SSID',

        unknownKey: 'Clé inconnue',
        undefinedDescription: 'Description non définie'
    },
    createDevice: 'Créer un appareil',
    detail: {
        informations: {
            actions: 'Actions',
            informationsTitle: 'Informations',
            latestDetections: 'Détections',
            localization: 'Localisation'
        },
        configuration: {
            configurationTitle: 'Configuration',
            connectivityTitle: 'Connexion',
            miscTitle: 'Autres'
        },
        detections: {
            detectionsTitle: 'Détections',
            table: {
                bib: 'Bib',
                checkpoint: 'Checkpoint',
                timestamp: 'Date',
                device: 'Appareil',
                event: 'Evènement',
                event_filter: 'Event filter',
                timer_filter: 'Timer filter'
            },
            liveDetections: 'Détections en direct',
            pastDetections: 'Détections précédentes'
        },
        documents: {
            documentsTitle: 'Documents'
        },
        history: {
            historyTitle: 'Historique de configuration',
            filters: {
                name: 'Nom de la clé'
            }
        },
        informationsTitle: 'Informations'
    },
    filters: {
        checkpoint: 'Checkpoint',
        event: 'Évènement',
        lowBattery: 'Batterie faible',
        lowSignal: 'Signal faible',
        name: 'Nom',
        nameOrSerial: 'Nom ou numéro de série'
    },
    latestDetections: 'Détections',
    model: {
        checkpointName: 'Checkpoint associé',
        description: 'Description',
        deviceName: "Nom de l'appareil",
        deviceType: "Type d'appareil",
        eventName: 'Evènement associé',
        firmwareUpdated: 'Dernière mise à jour du firmware',
        firmwareVersion: 'Version du firmware',
        imeiSAT: 'Imei SAT',
        lastMaintenanceDate: "Dernière date d'entretien",
        manufactureDate: 'Date de fabrication',
        organizationName: 'Propriétaire',
        serialNumber: 'Numéro de série',
        simIccid: 'Sim ICCID',

        config_status: 'Configuration en attente de synchronisation',
        config_synced_at: 'Dernière synchronisation de la configuration'
    },
    noDetectionFound: 'Aucune détection trouvée',
    rental: {
        rentalApiSuccess: 'Location crée avec succès !',
        rentalApiError: 'Erreur lors de la création de la location',
        action: "Louer l'appareil",
        title: "Louer l'appareil à une autre organisation",
        rentToOrganization: 'Organisation choisie',
        startAt: 'Début de la location',
        endAt: 'Fin de la location'
    },
    status: {
        online: 'En ligne',
        offline: 'Hors ligne',
        lowBattery: 'Batterie faible',
        lowSignal: 'Signal moyen',
        warning: 'A surveiller',

        lastBatteryLevel: 'Dernier niveau de batterie connu : {battery}',
        lastBatteryLevelUnknown: 'Dernier niveau de batterie inconnu',

        lastHelloBefore15Minutes: 'Dernière connexion : il y a {minutes} min.',
        lastHelloNow: 'Dernière connexion : maintenant.',
        lastHelloAfter15Minutes: 'Dernière connexion: il y a plus de 15 min.'
    },
    table: {
        alerts: 'Alertes',
        createDevice: 'Créer un appareil',
        deviceType: "Type d'appareil",
        name: 'Nom',
        currentEventTitle: 'Evènement associé'
    },
    title: {
        checkpointTitle: 'Checkpoint',
        eventTitle: 'Évènement',
        checkpointAssociatedTitle: 'Checkpoint associé',
        currentEventTitle: 'Evènement associé',
        noEventAssociated: 'Aucun évènement associé'
    }
}
