import { CRM_PREFIX, DEVICE_PREFIX } from '@/config/api'
import api from '@/helpers/api'
import auth from '@/helpers/auth'
import crm from '@/helpers/crm'
import { DEVICE_ROUTE } from '@/router/routes'
import { ORGANIZATIONS_ROUTE } from '@/router/routing/organizationRouting/organizationRoutes'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import type { AdminOrganizationFiltersType } from '@/types/filters/AdminFilters'
import type { DeviceFiltersType } from '@/types/filters/DeviceFilters'
import type { FilterType } from '@/types/filters/FilterType'
import type { AdminUserCreateModel } from '@/types/models/AdminUserCreateModel'
import type { ApiModel } from '@/types/models/ApiModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { OnboardingModel } from '@/types/models/OnboardingModel'
import type { OrganizationModel } from '@/types/models/OrganizationModel'
import type { RentalModel } from '@/types/models/RentalModel'
import type { UserModel } from '@/types/models/UserModel'
import { buildQueryUrl } from '@/utils/queryUtils'
import type { AxiosResponse } from 'axios'

const BASE_ORGANIZATION_ROUTE = `${CRM_PREFIX}${ORGANIZATIONS_ROUTE}`

const ORGANIZATION_PREFIX = (organizationId: number) =>
    `${BASE_ORGANIZATION_ROUTE}/${organizationId}`

const ORGANIZATION_USERS_PREFIX = (organizationId: number) =>
    `${ORGANIZATION_PREFIX(organizationId)}/users`

const ORGANIZATION_APIS_PREFIX = (organizationId: number) =>
    `${ORGANIZATION_PREFIX(organizationId)}/apis`

const GET_ORGANIZATION_DEVICES_PREFIX = `${DEVICE_PREFIX}/devices`
const ORGANIZATION_DEVICES_PREFIX = (organizationId: number) =>
    `${ORGANIZATION_PREFIX(organizationId)}/devices`

const organizationApi = {
    /* ADMIN/AUTH CALLS */
    getOrganization: (id: number) => auth.get(`${BASE_ORGANIZATION_ROUTE}/${id}`),
    getOrganizations: (
        params?: Partial<PaginationType & FilterType<AdminOrganizationFiltersType>>
    ): Promise<PaginationDataType<OrganizationModel[]>> =>
        auth
            .get(`${BASE_ORGANIZATION_ROUTE}${buildQueryUrl(params)}`)
            .then(response => response.data),
    createOrganization: (organizationData: OrganizationModel) =>
        auth.post(`${BASE_ORGANIZATION_ROUTE}`, organizationData),
    updateOrganization: (id: number, organizationData: any) =>
        auth.patch(`${BASE_ORGANIZATION_ROUTE}/${id}`, organizationData),
    deleteOrganization: (id: number) => auth.delete(`${BASE_ORGANIZATION_ROUTE}/${id}`),

    createUser: (userData: UserModel, id: number) =>
        crm.post(`${ORGANIZATION_USERS_PREFIX(id)}`, userData),
    createApi: (apiData: ApiModel, id: number) =>
        crm.post(`${ORGANIZATION_APIS_PREFIX(id)}`, apiData),
    initOrganizationWithFirstUser: (data: AdminUserCreateModel) =>
        crm.post(`${BASE_ORGANIZATION_ROUTE}/init`, data),

    getOnboarding: (uuid: string) =>
        api.get(`${ORGANIZATIONS_ROUTE}/onboarding${buildQueryUrl({ uuid })}`),
    validateOnboarding: (uuid: string, data: OnboardingModel) =>
        api.post(`${ORGANIZATIONS_ROUTE}/onboarding/validate${buildQueryUrl({ uuid })}`, data),
    onboarding: (data: OnboardingModel) => api.post(`${ORGANIZATIONS_ROUTE}/onboarding`, data),

    /* CRM CALLS */
    getMyOrganization: () => crm.get(`${BASE_ORGANIZATION_ROUTE}/me`),
    getMyDevices: (
        params: Partial<PaginationType & FilterType<DeviceFiltersType>>
    ): Promise<AxiosResponse<PaginationDataType<DeviceModel[]> | DeviceModel[]>> =>
        api.get(`${DEVICE_ROUTE}${buildQueryUrl(params)}`),

    getCrmOrganization: (organizationId: number) => crm.get(ORGANIZATION_PREFIX(organizationId)),
    getOrganizationUsers: (params: Partial<PaginationType>, organizationId: number) =>
        crm.get(`${ORGANIZATION_USERS_PREFIX(organizationId)}${buildQueryUrl(params)}`),
    attachUserToOrganization: (organizationId: number, userId: number) =>
        crm.post(`${ORGANIZATION_USERS_PREFIX(organizationId)}/attach/${userId}`),
    detachUserFromOrganization: (organizationId: number, userId: number) =>
        crm.post(`${ORGANIZATION_USERS_PREFIX(organizationId)}/detach/${userId}`),
    getOrganizationDevices: (
        organization_id: number,
        params: Partial<PaginationType & FilterType<DeviceFiltersType>>
    ) =>
        crm.get(
            `${GET_ORGANIZATION_DEVICES_PREFIX}${buildQueryUrl({
                ...params,
                filters: { ...params.filters, organization_id }
            })}`
        ),
    getOrganizationApis: (
        organization_id: number,
        params: Partial<PaginationType & FilterType<DeviceFiltersType>>
    ) => crm.get(`${ORGANIZATION_APIS_PREFIX(organization_id)}${buildQueryUrl(params)}`),
    attachDeviceToOrganization: (organizationId: number, deviceId: number) =>
        crm.post(`${ORGANIZATION_DEVICES_PREFIX(organizationId)}/attach/${deviceId}`),
    detachDeviceFromOrganization: (organizationId: number, deviceId: number) =>
        crm.post(`${ORGANIZATION_DEVICES_PREFIX(organizationId)}/detach/${deviceId}`),

    // RENTALS
    createRental: (organizationId: number, data: RentalModel) =>
        crm.post(`${ORGANIZATION_PREFIX(organizationId)}/rentals`, data)
}

export default organizationApi
